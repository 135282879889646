import React, { Component } from 'react';
import axios from 'axios';
import { css } from '@emotion/core';
import BarLoader from 'react-spinners/BarLoader';
import { withFirebase } from '../services/context';

const override = css`
  display: block;
  margin: auto;
  top: 330px;
`;

class Portal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      url: '',
      loading: true
    };
  }

  async fetchBillingPortalUrl() {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/billingPortalSession`, {
        headers: {
          'Authorization': `Bearer ${await this.props.firestore.auth.currentUser.getIdToken(true)}`
        }
      })
      if (response.status === 200) {
        return response.data.session.url;
      }
      else return null;
    } catch (error) {
      console.error({ error });
      return null
    }
  }

  async componentDidMount() {
    const url = await this.fetchBillingPortalUrl();
    if (url)
      window.location.href = url;
    this.setState({
      url,
      loading: false
    });
  }

  render() {
    return this.state.loading === true ? (
      < BarLoader
        css={override}
        size={35}
        color={'#123abc'}
        loading={this.state.loading}
      />
    ) : null;
  }
}

const PortalPage = withFirebase(Portal);
export default PortalPage;
