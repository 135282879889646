import React, { Component } from 'react';
import FireStore from './firestore';
import { FireStoreContext } from './context';

class FireStoreProvider extends Component {
  render() {
    return (
      <FireStoreContext.Provider
        value={new FireStore()}
      >
        {this.props.children}
      </FireStoreContext.Provider>
    );
  }
}

export default FireStoreProvider;
