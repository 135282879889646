
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { withFirebase } from '../services/context';
import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';
import { css } from '@emotion/core';
import BarLoader from 'react-spinners/BarLoader';
import SiteFooter from '../components/footer';
import SiteHeader from '../components/header';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const override = css`
  display: block;
  margin: 0 auto;
  top: 330px;
`;

class RedirectCheckout extends Component {
  state = {
    sessionId: null
  }

  async componentDidMount() {
    const { priceId } = this.props.match.params;
    this.props.firestore.auth.onAuthStateChanged(async (user) => {
      try {
        let sessionId = null;
        const customer = await this.props.firestore.getCustomer();
        const needSubscription = await this.props.firestore.subscriptionRequired(customer);
        if (needSubscription) {
          sessionId = await this.fetchCheckoutSession(priceId);
          const stripe = await stripePromise;
          await stripe.redirectToCheckout({ sessionId });
        }
        this.setState({ sessionId, customer });
      } catch (error) {
        console.error({ error });
      }
    });
  }

  async fetchCheckoutSession(priceId) {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/sessionId/${priceId}`, {
        headers: {
          'Authorization': `Bearer ${await this.props.firestore.auth.currentUser.getIdToken(true)}`
        }
      });
      if (response.status === 200) {
        return response.data.id;
      } else return null;
    } catch (error) {
      console.error({ error });
      return null;
    }
  }

  render() {
    const { customer } = this.state;
    if (!customer) {
      return (
        < BarLoader
          css={override}
          size={35}
          color={'#123abc'}
          loading={true}
        />
      );
    }
    if (!customer.stSubsId) {
      return (
        <>
          <SiteHeader></SiteHeader>
          <div className='container text-center py-5 mt15'>
            <p className='lead'>Wait, Redirecting to Checkout Page</p>
          </div>
          <SiteFooter></SiteFooter>
        </>
      )
    } else if (customer.stSubsId && customer.premium) {
      return (
        <>
          <SiteHeader></SiteHeader>
          <div className='container text-center py-5 mt15'>
            <p className='lead'>You have already Subscribed to one of our payment plans. Thanks!</p>
          </div>
          <SiteFooter></SiteFooter>
        </>
      )
    } else {
      return (
        <>
          <SiteHeader></SiteHeader>
          <div className='container text-center py-5 mt15'>
            <p className='lead'>There's an issue with the subscription. Please contact our support!</p>
          </div>
          <SiteFooter></SiteFooter>
        </>
      )
    };
  }
}

const RedirectCheckoutPage = withRouter(withFirebase(RedirectCheckout));
export default RedirectCheckoutPage;
