
import React, { Component, useEffect } from 'react';
import { withFirebase } from '../services/context';
import SiteHeader from '../components/header';
import SiteFooter from '../components/footer';
import { Redirect } from 'react-router-dom';
import GoogleButton from 'react-google-button'
import { trialDaysLeft } from '../helpers/helper';

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {
    this.handleAuthChange = this.handleAuthChange.bind(this);
    this.props.firestore.auth.onAuthStateChanged(this.handleAuthChange);
  }

  async handleAuthChange(user) {
    let authenticated = !!user, premium = false, subscribed = false, newCustomer = false;
    let daysLeft = 0;
    if (user) {
      const customer = await this.props.firestore.getCustomer();
      if (customer) {
        subscribed = await this.props.firestore.isSubscribed(customer);
        daysLeft = trialDaysLeft(customer.createdAt._seconds, 30);
        if (subscribed) {
          const needSubscription = await this.props.firestore.subscriptionRequired(customer);
          premium = !needSubscription;
        }
      } else {
        daysLeft = 30;
        await this.props.firestore.createCustomer();
      }
    }

    if (daysLeft > 29)
      newCustomer = true;

    // this.setState({ daysLeft });
    this.props.onUserStateChange({ authenticated, premium, subscribed, newCustomer });
  }

  signIn = () => {
    this.props.firestore.signInWithGoogle()
      .catch(error => {
        console.error({ error });
      });
  }

  render() {
    const { authenticated, premium, subscribed, newCustomer } = this.props;
    // const { daysLeft } = this.state;
    if (!authenticated) {
      return (
        <>
          <SiteHeader></SiteHeader>
          <div className='container mb-5 mt15 text-center' style={{ "margin-top": "15%", zIndex: 1000, position: 'relative' }}>
            {/* <div className="d-flex justify-content-center">
              <label className='mb-3' style={{ textAlign: 'center', fontSize: '20px' }}>Start your free 30-day trial</label>
            </div> */}
            <div className="mb-3 d-flex justify-content-center">
              <GoogleButton id='sign-in' type="dark" onClick={this.signIn} />
            </div>
            <div className="d-flex justify-content-center">
              <label className='mb-3 font-weight-bold' style={{ textAlign: 'center', fontSize: '17px' }}>No credit card details required</label> {/*  for 30-day free trial */}
            </div>
            <div className="mb-3 d-flex justify-content-center" style={{ fontSize: '14px' }}>
              <ul className="text-left m-0">
                <li>Get access to all premium personal finances templates</li>
                <li>Access valuable insights and data in your dashboard</li>
                <li>Automate the import of your bank transactions and balances</li>
              </ul>
            </div>
            {/* <div className="mb-3 d-flex justify-content-center">
              <label className='font-weight-bold' style={{ textAlign: 'center', fontSize: '14px' }}>After the trial you can upgrade to one of our premium plans.</label>
            </div>
            <div className="mb-1 d-flex justify-content-center">
              <label className='mb-3 font-weight-bold' style={{ textAlign: 'center', fontSize: '14px' }}>Available premium plans.</label>
            </div>
            <div className="row mb-3 justify-content-around">
              <div className="col-lg-4 col-md-12 col-sm-12 mt-1">
                <div className="plans w-100" style={{ background: '#0374B3' }}>
                  <label>Bronze</label>
                  <label className="font-weight-bold">€4.99/month</label>
                </div>
              </div>
              <div className="col-lg-4 col-md-12 col-sm-12 mt-1">
                <div className="plans w-100" style={{ background: '#0182CA' }}>
                  <label>Silver(save €2.95)</label>
                  <label className="font-weight-bold">€26.99/6month</label>
                </div>
              </div>
              <div className="col-lg-4 col-md-12 col-sm-12 mt-1">
                <div className="plans w-100" style={{ background: '#26B8EB' }}>
                  <label>Gold(save €9.89)</label>
                  <label className="font-weight-bold">€49.99/12months</label>
                </div>
              </div>
            </div>

            <div className="d-flex justify-content-center">
              <label className='mb-3 font-weight-bold' style={{ textAlign: 'center', fontSize: '14px' }}>No hidden charges. No contract. Cancel anytime.</label>
            </div> */}
          </div>
          <SiteFooter></SiteFooter>
        </>
      )
    } else
      return (
        <Redirect to={{ pathname: `/landing`, state: { premium, subscribed, newCustomer } }} />
      );
    /* else if (newCustomer || premium || subscribed) {
      return (
        <Redirect to={{ pathname: `/landing`, state: { premium, subscribed, newCustomer } }} />
      );
    } else
      return (
        <Redirect to={{ pathname: `/plans` }} push />
      ); */

    /* else {
      const { priceId } = this.props.match.params;
      const { pathname } = this.props.location;
      const to = priceId ? `/redirect_checkout/${priceId}` : '/plans';
      return (
        <Redirect
          to={{ pathname: to, state: { from: pathname } }}
        />
      ); 
  }*/
  }
}
const HomePage = withFirebase(Home);
export default HomePage
