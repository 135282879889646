import React, { Component } from 'react';
import './App.css';
import {
  Route,
  Switch,
  Redirect
} from 'react-router-dom';
import Home from './pages/home';
import RedirectCheckoutPage from './pages/redirect';
import LandingPage from './pages/landing/landing';
import { withFirebase } from './services/context';
import { css } from '@emotion/core';
import BarLoader from 'react-spinners/BarLoader';
import Success from './components/success';
import Failure from './components/failure';
import Plans from './pages/plans/plans';
import Portal from './pages/portal';

const PrivateRoute = ({ component: Component, authenticated, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props =>
        authenticated === true ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: `/`, state: { from: props.location } }}
          />
        )
      }
    />
  );
}

const SubscriptionRoute = ({ component: Component, subscribed, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props =>
        subscribed === true ? (
          <Component {...props} />
        ) : (
          <Redirect to={`/`} />
        )
      }
    />
  );
}

const PremiumRoute = ({ component: Component, premium, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props =>
        premium === true ? (
          <Component {...props} />
        ) : (
          <Redirect to={`/`} />
        )
      }
    />
  );
}

const NonPremiumRoute = ({ component: Component, subscribed, authenticated, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props =>
        authenticated && !subscribed ? (
          <Component {...props} />
        ) : (
          <Redirect to={`/`} />
        )
      }
    />
  );
}

const override = css`
  display: block;
  margin: auto;
  top: 330px;
`;

class MainApp extends Component {
  constructor(props) {
    super(props);
    this.handleUserState = this.handleUserState.bind(this);
    this.state = {
      authenticated: false,
      premium: false,
      loading: true
    };
  }

  componentDidMount() {
    this.setState({
      authenticated: false,
      premium: false,
      loading: false
    });
  }

  handleUserState({ authenticated, premium, subscribed, newCustomer }) {
    this.setState({
      premium,
      authenticated,
      loading: false,
      subscribed,
      newCustomer
    });
  }

  render() {
    return this.state.loading === true ? (
      < BarLoader
        css={override}
        size={35}
        color={'#123abc'}
        loading={this.state.loading}
      />
    ) : (
      <Switch>
        {/* Stripe Redirect Pages */}
        <Route
          path='/success'
          component={Success}
        />
        <Route exact
          path='/cancel'
          component={Failure}
        />
        {/* Stripe Redirect Pages end */}
        {/* <PrivateRoute exact
          path='/plans'
          authenticated={this.state.authenticated}
          component={Plans} /> */}
        <Route exact
          render={(props) => (
            <Home {...props}
              onUserStateChange={this.handleUserState}
              authenticated={this.state.authenticated}
              premium={this.state.premium}
              subscribed={this.state.subscribed}
              newCustomer={this.state.newCustomer}
            />
          )}
          path='/'
        />
        <Route exact
          render={(props) => (
            <Home {...props}
              onUserStateChange={this.handleUserState}
              authenticated={this.state.authenticated}
              premium={this.state.premium}
            />
          )}
          path='/subscribe/:priceId'
        />
        <SubscriptionRoute exact
          path='/portal'
          subscribed={this.state.subscribed}
          component={Portal}
        />
        <PrivateRoute exact
          path='/redirect_checkout/:priceId'
          authenticated={this.state.authenticated}
          component={RedirectCheckoutPage}
        />
        <PrivateRoute exact
          path='/landing'
          authenticated={this.state.authenticated}
          component={LandingPage}
        />
      </Switch>
    );
  }
}
const App = withFirebase(MainApp);
export default App;
