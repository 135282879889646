
import React, { Component } from 'react';

class Success extends Component {
  render() {
    return (
      <main className="success-main">
        <div className="row">
          <img alt="nordveld-success" className="success-img"
            src="https://static.wixstatic.com/media/181739_6558334ca4d34e989e387a71c0720f50~mv2.png"
          />
        </div>
        <div className="row">
          <div className="col-12">
            <h1 className="success-h1">Success!</h1>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-12">
            <p className="success-p">Thank you for subscribing to Nordveld Flow</p>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <a target="_blank" rel="noopener noreferrer" href="https://gsuite.google.com/marketplace/app/nordveld_flow/194559993589">
              Go to Nordveld Addon
            </a>
          </div>
        </div>
      </main>
    );
  }
}

export default Success;
