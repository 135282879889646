
import React, { Component } from 'react';

class SiteFooter extends Component {
  render() {
    const { fixedFooter } = this.props;
    return (
      <footer className={`footer-bg footer-h ${fixedFooter ? '' : 'fixed-bottom'}`} style={{ zIndex: 0 }} >
      </footer >
    );
  }
}

export default SiteFooter;
