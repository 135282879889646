
import React, { Component } from 'react';

class SiteHeader extends Component {
  render() {
    return (
      <header>
        <nav className="navbar fixed-top navbar-expand-lg header-bg scrolling-navbar header-bg-h">
          <div className="logo-wrapper waves-light m-auto">
            <img src="https://static.wixstatic.com/media/181739_50f13121d124444ca3eb019c395c3304~mv2.png"
              alt="norveld-logo" className="img-fluid flex-center"
            />
          </div>
        </nav>
      </header>
    );
  }
}

export default SiteHeader;
