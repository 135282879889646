
import React, { Component, history } from 'react';
import SiteHeader from '../../components/header';
import SiteFooter from '../../components/footer';
import { Link } from 'react-router-dom';
import { css } from '@emotion/core';
import { withFirebase } from '../../services/context';
import BarLoader from 'react-spinners/BarLoader';
import './landing.css';

const override = css`
  display: block;
  margin: auto;
  top: 330px;
`;

class Landing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      addOnInstalled: false,
      premium: false,
      newCustomer: props.location.state.newCustomer,
      subscribed: props.location.state.subscribed
    };
    console.log({
      newCustomer: props.location.state.newCustomer,
      subscribed: props.location.state.subscribed
    });
  }

  async componentDidMount() {
    const customer = await this.props.firestore.getCustomer();
    if (customer) {
      const needSubscription = await this.props.firestore.subscriptionRequired(customer);
      this.setState({
        loading: false,
        addOnInstalled: customer.userKey ? true : false,
        premium: !needSubscription
      });
    }
  }

  render() {
    const { loading, addOnInstalled, premium, subscribed } = this.state;
    if (loading) {
      return (
        < BarLoader
          css={override}
          size={35}
          color={'#123abc'}
          loading={true}
        />
      );
    }
    return (
      <>
        <SiteHeader></SiteHeader>
        <section id="plan-section" className="d-flex align-items-center">
          <div
            className="container position-relative"
            data-aos="fade-up"
            data-aos-delay={100}
          >
            {/* <div className="row justify-content-center mb-3">
              <iframe width="560" height="315" src="https://www.youtube.com/embed/6W6CVF99iRU" title="Welcome to Nordveld Youtube Video" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div> */}
            <div className="row justify-content-center mb-3">
              <div className="col-xl-12 col-lg-10 col-md-9 text-center">
                <p className="trialtext">Thank you for signing up for the Nordveld Flow Trial 🎉</p>
                <p className="trialtext">In order to get started you might want to check out the starting spreadsheet. You will also find a link to our tutorial section on our website, where there are more tutorials available.</p>
                <p className="trialtext">Here you will find the helpcenter. If you have any questions, feedback or suggestions on banks or other institutions to add please create a ticket there and we will follow up with you.</p>
              </div>
            </div>
            <div className="d-flex justify-content-center align-items-baseline position-relative mb-4">
              Install the Add-On here
              <button id='sign-in' className='btn google-btn social-btn mb-2 mr-3 ml-2' type='button'>
                <span>
                  <a target="_blank" className="text-white" rel="noopener noreferrer" href="https://workspace.google.com/marketplace/search/Nordveld%20Flow">
                    Install Add-On
                  </a>
                </span>
              </button>
              {/* <div className="justify-content-center align-items-center">
                <input type="checkbox" className="check" defaultChecked={addOnInstalled} disabled={true} />
                <span>
                  Installed
                </span>
              </div> */}
            </div>
            <div className="row justify-content-center mb-4 align-items-center">
              Open and create a copy of this sheet by
              <button id='sign-in' className='btn google-btn social-btn ml-2' type='button'>
                <span>
                  <a target="_blank" className="text-white" rel="noopener noreferrer" href="https://docs.google.com/spreadsheets/d/1fYKvDGz50Ur7M_Mqh_wVwgFEQg4dicIKkCXzpiaI9YE/copy">
                    Clicking Here
                  </a>
                </span>
              </button>
            </div>
            {subscribed ? <div className="d-flex justify-content-center align-items-baseline position-relative mb-4">
              To check your billing details & invoices
              <button id='sign-in' className='btn google-btn social-btn text-white ml-2' type='button'>
                <span>
                  <Link style={{ color: "#FFFFFF" }} to="/portal" >Go to billing</Link>
                </span>
              </button>
            </div> : null}
            {/* <div className="row justify-content-center mb-4 align-items-center">
              Watch the video below to find the add-on on the Google sheet and get started using the tool.
            </div> */}
            <div className="row justify-content-center mb-2">
              <div className="col-xl-7 col-lg-9 text-center">
                {/* <p className="trialtext mb-4">Getting started</p> */}
                {/* <video src="https://www.loom.com/share/faea15087b334f13a78f0afc76013330" width="600" height="300" controls="controls" autoPlay={true} /> */}
                {/* <div style="position: relative; padding-bottom: 102.27272727272727%; height: 0;"><iframe src="https://www.youtube.com/watch?v=RNafK7tVDQA" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen style="position: absolute; top: 0; left: 0; width: 300; height: 600;"></iframe></div> */}
                {/* <div className="mb-2">
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/videoseries?list=PLB5s1507qkdvuJHpoqID7wATMRPvTRuzb" title="How to Install Nordveld Addon Youtube Video" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe>
                </div> */}
                <a target="_blank" rel="noopener noreferrer" href="https://www.nordveld.com/post/installing-nordveld-flow">
                  You can watch more video tutorials on how to link your bank and set up other parts of the tool on the "Watch or Read Tutorials Here" tab of the Google sheet template and on the welcome email that you'll get.
                </a>
                <br></br>
                <br></br>
                <span target="_blank" rel="noopener noreferrer">
                  <b>Note:</b> You can only view the "Watch or Read Tutorials Here" tab on the Google sheet once you click on the "Open Control Panel" menu and the templates are done populating.
                </span>
              </div>
            </div>
            {/* <div className="row justify-content-center">
              <div className="col-xl-7 col-lg-9 text-center">
                <p className="trialtext">Using Nordveld for the first time.</p>
                <div><iframe src="https://www.loom.com/embed/faea15087b334f13a78f0afc76013330" frameBorder="0" width="100%" height="300"></iframe></div>
                <a target="_blank" rel="noopener noreferrer" href="https://www.nordveld.com/post/why-choose-nordveld">
                  Using Nordveld for the first time.
                </a>
              </div>
            </div> */}
          </div>
        </section>
        <SiteFooter fixedFooter={true}></SiteFooter>
      </>
    );
  }
}

const LandingPage = withFirebase(Landing);
export default LandingPage;
