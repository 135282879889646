import React from 'react';

const ServerContext = React.createContext('ServerContext');
const FireStoreContext = React.createContext('FireStoreContext');
export const withFirebase = Component => props => (
  <FireStoreContext.Consumer>
    {firestore => <Component {...props} firestore={firestore} />}
  </FireStoreContext.Consumer>
);

export { ServerContext, FireStoreContext };
