module.exports.trialDaysLeft = (joinedDate, customTrial = 0) => {
  if (joinedDate) {
    const now = new Date();
    const joined = new Date(joinedDate * 1000);
    if (customTrial > 0) joined.setDate(joined.getDate() + customTrial);

    const msPerDay = 1000 * 60 * 60 * 24;
    // Discard the time and time-zone information.
    const utc1 = Date.UTC(now.getFullYear(), now.getMonth(), now.getDate());
    const utc2 = Date.UTC(joined.getFullYear(), joined.getMonth(), joined.getDate());

    return Math.floor((utc2 - utc1) / msPerDay);
  }
  return 0;
}
