import firebase from 'firebase';
import 'firebase/auth';
import axios from 'axios';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DB_URL,
  projectId: process.env.REACT_APP_PROJECT_ID
};

class FireStore {
  userCollection = 'users';

  constructor() {
    try {
      let app = null;
      if (firebase.apps.length === 0) {
        app = firebase.initializeApp(firebaseConfig);
      } else {
        app = firebase.app();
      }

      this.auth = firebase.auth(app);
    } catch (error) {
      console.error({ error });
    };
  }

  async _getheaders() {
    if (this.auth && this.auth.currentUser)
      return {
        'Authorization': `Bearer ${await this.auth.currentUser.getIdToken(true)}`
      };
    return null;
  }

  async createCustomer() {
    try {
      let response = await axios.post(`${process.env.REACT_APP_API_URL}/customer`, {}, {
        headers: await this._getheaders(),
      });
      return response.data;
    } catch (error) {
      console.error({ error });
      return null;
    }
  }

  async getCustomer() {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/customer`, {
        headers: await this._getheaders()
      });
      return response.data;
    } catch (error) {
      console.error({ error });
      return null;
    }
  }

  async subscriptionRequired(customer) {
    let needsSubscription = true;
    if (customer && ['active', 'past_due'].includes(customer.stSubsStatus))
      needsSubscription = false;

    return needsSubscription;
  }

  async isSubscribed(customer) {
    let isSubscribed = false;

    // TODO: add cancelled status check
    if (customer && customer.stCusId && customer.stSubsId)
      isSubscribed = true;

    return isSubscribed;
  }

  async signInWithGoogle() {
    const provider = new firebase.auth.GoogleAuthProvider();
    return this.auth.signInWithPopup(provider);
  }

  async signOut() {
    await this.auth.signOut();
  }

}

export default FireStore;
