
import React, { Component } from 'react';
import SiteHeader from '../../components/header';
import SiteFooter from '../../components/footer';
import { Link } from 'react-router-dom';
import { css } from '@emotion/core';
import { withFirebase } from '../../services/context';
import BarLoader from 'react-spinners/BarLoader';
import './plans.css';
import { trialDaysLeft } from '../../helpers/helper';
import BronzeTrophy from "../../assets/bronze.svg";
import SilverTrophy from "../../assets/silver.svg";
import GoldTrophy from "../../assets/gold.svg";

const override = css`
  display: block;
  margin: auto;
  top: 330px;
`;

class Plans extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true
    };
  }

  async componentDidMount() {
    const customer = await this.props.firestore.getCustomer();
    if (customer) {
      const daysLeft = trialDaysLeft(customer.createdAt._seconds, 30);
      this.setState({
        loading: false,
        daysLeft: daysLeft > 0 ? daysLeft : 0
      });
    }
  }

  render() {
    const { daysLeft, loading } = this.state;
    if (loading) {
      return (
        < BarLoader
          css={override}
          size={35}
          color={'#123abc'}
          loading={true}
        />
      );
    }
    let expireText = 'Your trial period for Nordveld is over.';
    let expireInstruct = 'Please upgrade now to continue using Nordveld Flow in automating your personal finance management.'
    if (daysLeft > 0) {
      expireText = `You still have ${daysLeft} remaining days for your free trial, but you can upgrade to premium now.`
      expireInstruct = 'If you upgrade now or prior to the end of your 30-day free trial you’ll get an automatic one-time 10% discount. The best part is you don’t get charge today. We will only charge your card for the discounted purchase after your trial ends.'
    }

    return (
      <>
        <SiteHeader></SiteHeader>
        <section id="plan-section" className="d-flex align-items-center">
          <div
            className="container position-relative"
            data-aos="fade-up"
            data-aos-delay={100}
          >
            <div className="row justify-content-center">
              <div className="col-xl-10 col-lg-9 text-center">
                <p className="tryfreetext">{expireText}</p>
                <p className="expireinstruct">{expireInstruct}</p>
                {/* <hr className="line" />
                <h2 className="trial">To continually experience the benefits of Nordveld Flow in managing your personal finance, it's best to subscribe to one of our Premium Plans below.</h2> */}
              </div>
            </div>
            <div className="container">
              <div className="card-group">
                <div className="card crdmov">
                  <div className="card-header">
                    <div className="row">
                      <div className="col col-md-9">
                        <p className="headname">Bronze</p>
                        <h2 className="boldtext">4.99€/month</h2>
                      </div>
                      <div className="col col-md-3">
                        <img src={BronzeTrophy} className="imgfloat" alt="nordveld-monthly-plan" />
                      </div>
                    </div>
                  </div>
                  <div className="card-block">
                    <ul>
                      <br />
                      <li>No credit card details required for 30-day free trial</li>
                      <li>Get access to all premium personal finance templates</li>
                      <li>Access valuable insights and data in your dashboard</li>
                      <li>No hidden charges</li>
                    </ul>
                    <div className="text-center">
                      <Link to="/redirect_checkout/bronze" className="btn-get-started scrollto getstrt">
                        Get Started
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="card crdmov">
                  <div className="card-header">
                    <div className="row mb-1">
                      <div className="col col-md-9 d-flex align-items-center">
                        <div className="mr-4">
                          <p className="silver-card mb-1">Silver (Save €2.95)</p>
                          <h2 className="silver-card boldtext">26.99€/6 months</h2>
                        </div>
                        <p class="card-price m-0">Most Popular</p>
                      </div>
                      <div className="col col-md-3">
                        <img src={SilverTrophy} className="imgfloat" alt="nordveld-quarterly-plan" />
                      </div>
                    </div>
                  </div>
                  <div className="card-block">
                    <ul>
                      <br />
                      <li>No credit card details required for 30-day free trial</li>
                      <li>Get access to all premium personal finance templates</li>
                      <li>Access valuable insights and data in your dashboard</li>
                      <li>No hidden charges</li>
                    </ul>
                    <div className="text-center">
                      <Link to="/redirect_checkout/silver" className="btn-get-started scrollto getstrt silverstart">
                        Get Started
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="card crdd">
                  <div className="card-header hed">
                    <div className="row">
                      <div className="col col-md-9">
                        <p className="headname">Gold (Save €9.89)</p>
                        <h2 className="boldtext">49.99€/year</h2>
                      </div>
                      <div className="col col-md-3">
                        <img src={GoldTrophy} className="imgfloat" alt="nordveld-yearly-plan" />
                      </div>
                    </div>
                  </div>
                  <div className="card-block">
                    <ul>
                      <br />
                      <li>No credit card details required for 30-day free trial</li>
                      <li>Get access to all premium personal finance templates</li>
                      <li>Access valuable insights and data in your dashboard</li>
                      <li>No hidden charges</li>
                    </ul>
                    <div className="text-center">
                      <Link to="/redirect_checkout/gold" className="btn-get-started scrollto getstrt">
                        Get Started
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <h2 className="toptxt">All plans include:</h2>
              <div className="row avt">
                <div className="col">
                  <div className="card promoting-card crdbg">
                    {/* Card content */}
                    <div className="card-body d-flex flex-row">
                      {/* Avatar */}
                      <img
                        src="https://static.wixstatic.com/media/181739_333aff14dcfc4fcc983ef53e0bfce674~mv2.png"
                        className="rounded-circle mr-3"
                        alt="avatar"
                        width="120px"
                        height="120px"
                      />
                      {/* Content */}
                      <div>
                        {/* Title */}
                        <h4 className="titlenum">Effortless control of your finances</h4>
                        {/* Subtitle */}
                        <p className="para">
                          Effortless control of your finances through Google Sheets
                          integration with 2000+ financial institutions in Europe
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="card promoting-card crdbg">
                    {/* Card content */}
                    <div className="card-body d-flex flex-row">
                      {/* Avatar */}
                      <img
                        src="https://static.wixstatic.com/media/181739_26b5412a4c454735a2dc3a676f802c54~mv2.png"
                        className="rounded-circle mr-3"
                        alt="avatar"
                        width="120px"
                        height="120px"
                      />
                      {/* Content */}
                      <div>
                        {/* Title */}
                        <h4 className="titlenum">More value out of your time and money</h4>
                        {/* Subtitle */}
                        <p className="para">
                          More value out of your time and money by using the continuously updated and improved Google Sheet templates for personal and small business use
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row avt">
                <div className="col">
                  <div className="card promoting-card crdbg">
                    {/* Card content */}
                    <div className="card-body d-flex flex-row">
                      {/* Avatar */}
                      <img
                        src="https://static.wixstatic.com/media/181739_355882a46f7940939fc9289cfa8982b2~mv2.png"
                        className="rounded-circle mr-3"
                        alt="avatar"
                        width="120px"
                        height="120px"
                      />
                      {/* Content */}
                      <div>
                        {/* Title */}
                        <h4 className="titlenum">Collect and view ALL of your bank data</h4>
                        {/* Subtitle */}
                        <p className="para">
                          Collect and view ALL of your bank data in one single place, following the journey of your personal finances through net worth reports, balance sheets and so on.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="card promoting-card crdbg">
                    {/* Card content */}
                    <div className="card-body d-flex flex-row">
                      {/* Avatar */}
                      <img
                        src="https://static.wixstatic.com/media/181739_52328f2fefaa4f6cb977130ea7543462~mv2.png"
                        className="rounded-circle mr-3"
                        alt="avatar"
                        width="120px"
                        height="120px"
                      />
                      {/* Content */}
                      <div>
                        {/* Title */}
                        <h4 className="titlenum">Support 24/7</h4>
                        {/* Subtitle */}
                        <p className="para">Access to our support 24/7</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <h2 className="toptxt">Some questions you may have</h2>
              <br />
              <div className="row ques">
                <div className="col">
                  <h4 className="q1">Is it secure?</h4>
                  <p className="ans">Yes. The bank connection is secured using Strong Customer Authentication, the same principle in use with the majority of banks in Europe today. All of the sensitive data will be stored in your Google account, on Google infrastructure.</p>
                </div>
                <div className="col">
                  <h4 className="q1">Will you see my data?</h4>
                  <p className="ans">
                    Absolutely not. We don’t want the burden of that.
                  </p>
                </div>
                <div className="col"></div>
              </div>
            </div>
          </div>
        </section>
        <SiteFooter fixedFooter={true}></SiteFooter>
      </>
    );
  }
}

const PlansPage = withFirebase(Plans);
export default PlansPage;
