
import React, { Component } from 'react';

class Failure extends Component {
  render() {
    return (
      <main className="success-main">
        <div className="text-center">
          <img className="failure-img" alt="Something went wrong"
            src="https://partner-engineering-cdn.atl-paas.net/error.png" />
          <h1 className="success-h1 mt-4">Something went wrong</h1>
          <p className="success-p">
            We weren't able to subscribe you.
              <br />
              Give it a moment and try again.
            </p>
        </div>
      </main>
    );
  }
}

export default Failure;
